

export default function DataModal(props) {

  const { data, setShowModal, showModal, isComposite, headerText } = props
  const renderModalContent = () => {
    if (!data) return 'Loading...';
  
    const createContent = (obj, level = 0) => {
      let content = '';
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (Array.isArray(obj[key])) {
            // Handle arrays
            content += `
              <div style="display: flex; justify-content: space-between; margin-left: ${level * 20}px;">
                <strong>${key}:</strong> <span>${obj[key].join(', ')}</span>
              </div>`;
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Handle nested objects
            content += `
              <div style="margin-left: ${level * 20}px;">
                <div style="display: flex; justify-content: space-between;">
                  <strong>${key}:</strong> 
                </div>
                <div>${createContent(obj[key], level + 1)}</div>
              </div>`;
          } else {
            // Handle primitive values
            content += `
              <div style="display: flex; justify-content: space-between; margin-left: ${level * 20}px;">
                <strong>${key}:</strong> <span>${obj[key]}</span>
              </div>`;
          }
        }
      }
      return content;
    };
  
    return createContent(data);
  };
  

  
  
  
  
  
  return (
    <div class="modal fade show" id="myModal" style={showModal ? { display: 'block' } : { display: 'none' }} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <strong>{headerText}</strong>
            <button type="button" class="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
          </div>
          {isComposite ?

            <div className="modal-body modal-scroll-vh">
              <h6 className="mb-3">Do you want to generate page through AI or manually?</h6>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => {
                    const url = `/lodgeit?abn=${data?.abn || data?.ABN}&source=ai`;
                    window.open(url, '_self')
                  }} type="button" class="btn btn-primary me-3"><i class="bi bi-stars me-1"></i> Generate through AI</button>
                {/* <button onClick={() => {
                  const url = `/lodgeit?abn=${data?.abn}&source=manual`;
                  window.open(url, '_self')
                }} type="button" class="btn btn-outline-primary me-3"><i class="bi bi-pencil-square me-1"></i>Generate manually</button> */}
              </div>
            </div>
            :
            <div
              className="modal-body modal-scroll-vh"
              dangerouslySetInnerHTML={{ __html: renderModalContent() }}
            />
          }

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" onClick={() => { setShowModal(false) }}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )

}