import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Dialog,
  } from "@mui/material";
  import React from "react";
  
  import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
  import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
  import { MercDetailInfo } from "./mercinfo";
  import { BtcDetailInfo } from "./btcinfo";
  
  export const Satoshibudget = (props) => (
    <Card
      sx={{
        height: "100%",
        width: "350px",
        minHeight: "235px",
        backgroundColor: "rgba(61, 61, 61, 0.28)",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        borderRadius: "20px",
        position: "relative",
      }}
      {...props}
    > 
      <CardContent>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography
              color="rgba(255, 255, 255, 0.4)"
              gutterBottom
              variant="overline"
            >
              {props.title}
            </Typography>
            <Typography
              color="white"
              variant="h5"
              textAlign="left"
              marginLeft={2}
            >
              {props.value}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: "error.main",
                height: 56,
                width: 56,
              }}
              src={props.image}
              alt={props.alt}
            ></Avatar>
          </Grid>
        </Grid>
        <div>
          {props.sub_items ? (
            <Grid container spacing={1} pt={2}>
              {props.sub_items.map((sub_item) => (
                <Grid container key={sub_item.title}>
                  <Grid item xs={7}>
                    <Typography
                      color="white"
                      sx={{
                        mr: 1,
                      }}
                      variant="body2"
                      title={sub_item.tooltip ? sub_item.tooltip : props.title}
                    >
                      {sub_item.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      color="rgba(255, 255, 255, 0.4)"
                      variant="caption"
                    >
                      {sub_item.content}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div></div>
          )}
        </div>
  
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: "30px",
          }}
        >
          {props.down ? <ArrowDownwardIcon color="error" /> : <div></div>}
          {props.up ? <ArrowUpwardIcon color="error" /> : <div></div>}
          <Typography
            color="error"
            sx={{
              mr: 1,
            }}
            variant="body2"
          >
            {props.percent ? props.percent : ""}
          </Typography>
          <Typography
            color="white"
            variant="caption"
            sx={{
              mr: 5,
            }}
          >
            {props.base ? props.base : "Since last month"}
          </Typography>
  
          {/* {props.type === "merc" ? (
            <MercDetailInfo />
          ) : props.type === "btc" ? (
            <BtcDetailInfo />
          ) : (
            <div></div>
          )} */}
        </Box>
      </CardContent>
    </Card>
  );
  