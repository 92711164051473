import React, { useEffect, useState } from "react";

import { Satoshibudget } from "./components/satoshiBudget";
import { useGlobalState } from '../common/globalState';
import mercLogo from "../icons/merc-logo.png";

export const SatoshiTotalSupply = () => {
    const [state, dispatch] = useGlobalState();
    const [val, setVal] = useState("...");
    const [subVal, setSubVal] = useState(null);
    
    useEffect(()=>{
        setVal(state?.totalSupply?.toLocaleString("en-US"));
        var si = [];
        setSubVal(si);
    }, [state?.totalSupply, state.mercReserves, state.layer2Total, state.exchangeBalance])

    return (
        <>
         {/* <Satoshibudget 
         title="Satoshi total supply"
        value={val}
         up="true"
         percent="1%"
         image={mercLogo}
         alt="merc-reserve"
         sub_items={subVal}
         type="merc"
         /> */}
        
        </>
    )
}